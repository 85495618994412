import React, { Component } from "react";

export default class Navbar extends Component {
  fullScreen() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    let { logo, backLink, staticUrl } = this.props.data;
    // let notification = `${staticUrl}/notifications`;
    // let profile = `${staticUrl}/profile`;

    return (
      <>
        <nav class="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a href={staticUrl}>
              <img src={logo} className="mobile-logo ml-5" alt="logo"></img>
            </a>

            <div class="collapse navbar-collapse">
              <ul class="navbar-nav mr-auto"></ul>

              <a href={backLink} className="mr-4 btn btn-outline-dark btn-sm">
                <i className="fa fa-long-arrow-alt-left mr-2 fa-lg black-logo"></i>
                <b>Geri Dön</b>
              </a>
{/* 
              <a href={notification} className="mr-4">
                <i className="fa fa-bell fa-lg white-logo"></i>
              </a> */}

              <span
                style={{ cursor: "pointer" }}
                onClick={this.fullScreen}
                className="mr-4 ml-1"
              >
                <i className="fa fa-expand fa-lg white-logo"></i>
              </span>

              {/* <a href={profile} className="ml-1">
                <i className="fa fa-user fa-lg white-logo"></i>
              </a> */}
            </div>
          </div>
        </nav>
      </>
    );
  }
}
