import React, { useEffect, useState } from "react";
import UrlParser from "url-parse";
import '../style.css';

export default function PayTr({ url }) {
  const [params, setParams] = useState({
    token: "",
    logo: ""
  });

  const [iframeLink, setIframeLink] = useState("");

  useEffect(() => {
    let linkData = new UrlParser(url, true);
    let { query } = linkData;

    setParams({
      token :  window.atob(query.token),
      logo : window.atob(query.logo)
    });

    if(query.paymentType === "EFT"){
      setIframeLink(`https://www.paytr.com/odeme/api/${window.atob(query.token)}`)
    }
    else {
      setIframeLink(`https://www.paytr.com/odeme/guvenli/${window.atob(query.token)}`)
    }

  }, [url]);
  
  return (
    <div>
      <div className="text-center page-background">
        <div className="pt-3">
          <img
            style={{ maxWidth: "250px" }}
            src={params.logo}
            alt="Ana Sayfa"
          />
        </div>
        <div className="">
          <iframe
            src={iframeLink}
            id="paytriframe"
            frameBorder='0'
            scrolling='no'
            title='iframe'
          ></iframe>
        </div>
      </div>
     
    </div>
  );
}
