import { Component } from "react";
import UrlParser from "url-parse";
import Navbar from "./Navbar";
import Aside from "./Aside";
import BottomNavbar from "./BottomNavbar";

export default class Iframe extends Component {
  state = {
    iframeLink: "",
    courseName: "",
    backLink: "",
    logo: "",
    teacherName: "",
    topicName: "",
    unitName: "",
    staticUrl: "https://kursadresi.com", // TODO: from server
  };

  componentDidMount() {
    let { url } = this.props;
    let linkData = new UrlParser(url, true);
    let { query } = linkData;

    let courseName = window.atob(query.courseName);
    let backLink = window.atob(query.back);
    let logo = window.atob(query.logo);
    let teacherName = window.atob(query.teacher);
    let topicName = window.atob(query.topicName);
    let unitName = window.atob(query.unitName);
    let iframeLink = window.atob(query.iframeLink);

    let stateValue = {
      courseName,
      backLink,
      logo,
      teacherName,
      topicName,
      unitName,
    };

    stateValue.iframeLink =
      iframeLink.indexOf("youtube") !== -1
        ? this.youtubeEmbedLinkGenerator(iframeLink)
        : window.atob(query.iframeLink);

    this.setState(stateValue);
  }

  youtubeEmbedLinkGenerator = (iframeLink) => {
    let videoId = iframeLink.split("v=")[1];
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }

    return `https://www.youtube.com/embed/${videoId}`;
  };

  render() {
    let {
      iframeLink,
      courseName,
      backLink,
      logo,
      teacherName,
      topicName,
      unitName,
      staticUrl,
    } = this.state;

    return (
      <div>
        <Navbar data={{ logo, backLink, staticUrl }} />
        <div class="row">
          <div className="col-sm-2">
            <Aside data={{ staticUrl }} />
          </div>
          <div className="col-sm-10 mt-3 iframe-content">
            <BottomNavbar
              data={{ courseName, unitName, topicName, teacherName }}
            />
            <iframe
              style={{
                height: "100vh",
                border: "1px solid rgb(194, 194, 194)",
              }}
              id="video"
              src={iframeLink}
              title="iframe video"
              frameborder="0"
              allowfullscreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              className="mt-4 col-sm-11"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}
