import React, { Component } from "react";

export default class BottomNavbar extends Component {
  render() {
    console.log(this.props.data);

    let { courseName, unitName, topicName, teacherName } = this.props.data;

    return (
      <>
        <div className="col-sm-11">
          <div className="card p-2 header-card shadow-sm">
            <div class="card-body">
              <div className="row">
                <div className="col-sm-3">
                  <p>
                    <b>Eğitim: </b> {courseName}
                  </p>
                </div>

                <div className="col-sm-3">
                  <p>
                    <b>Ünite: </b> {unitName}
                  </p>
                </div>

                <div className="col-sm-3">
                  <p>
                    <b>Konu: </b> {topicName}
                  </p>
                </div>

                <div className="col-sm-3">
                  <p>
                    <b>Eğitmen: </b> {teacherName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/*

     
*/
