import React, { Component } from "react";

export default class Aside extends Component {
  render() {
    let { staticUrl } = this.props.data;
    let liveLessons = `${staticUrl}/active-educations`;
    let completedEducations = `${staticUrl}/completed-educations`;
    let exams = `${staticUrl}/exams`;
    let homeWork = `${staticUrl}/homeworks`;
    let trainingVideos = `${staticUrl}/training-videos`;

    return (
      <div class="wrapper">
        <div class="sidebar shadow">
          <ul>
            <li>
              <a href={staticUrl}>
                <i class="fas fa-home ml-1"></i>
                <b className="ml-4">Ana Sayfa</b>
              </a>
            </li>
            <li>
              <a href={liveLessons}>
                <i class="fa fa-user-graduate" ml-1></i>
                <b className="ml-4">Güncel Eğitimlerim</b>
              </a>
            </li>
            <li>
              <a href={completedEducations}>
                <i class="fas fa-clipboard-list ml-1"></i>
                <b className="ml-4">Tamamlanmış Eğitimlerim</b>
              </a>
            </li>

            <li>
              <a href={exams}>
                <i class="fas fa-diagnoses ml-1"></i>
                <b className="ml-4">Sınavlar</b>
              </a>
            </li>

            <li>
              <a href={homeWork}>
                <i class="fas fa-file-invoice ml-1"></i>
                <b className="ml-4">Ödevler</b>
              </a>
            </li>

            <li>
              <a href={trainingVideos}>
                <i class="fas fa-file ml-1"></i>
                <b className="ml-4">Ortak Döküman & Videolar</b>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
