import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Iframe from "./component/Iframe";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./style.css";
import PayTr from "./component/PayTr";

export default class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route
              path="watch"
              element={<Iframe url={window.location.href} />}
            />

            <Route
              path="paytr-payment"
              element={<PayTr url={window.location.href}/>}
            />
          </Routes>
        </BrowserRouter>
      </>
    );
  }
}
